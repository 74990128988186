import axios from "axios";
import React from "react";
import { AppConfigData } from "./CommonService";
import { Cookies } from "react-cookie-consent";
export const Login = async (ReqData) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "authmanagement/api/Auth/AdminLogin",
      data: ReqData,
      // headers: {
      //   "Content-Type": "application/json",
      //   accept: "*/*",
      //   // Authorization: "Bearer ",
      // },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const Logout = () => {
  return <div>Logout</div>;
};
export const getSuperAdminChangePassword = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/SuperAdminChangePassword",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error) {
    let data: any = {
      data: {
        success: false,
        StatusCode: "02",
        Message: "Something Wrong in API",
      }
    }
    return data;
  }
}