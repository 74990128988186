import React, { useEffect, useState } from 'react'
import TableView from '../../Component/Table/Index';
import { Button } from 'react-bootstrap';
import InPageNav from '../Common/InPageNav/Index';
import AddKnowledgeTutorial from '../Model/Knowledge/AddKnowledgeTutorial';
import { GetKnowledgeTutorialsList, KnowledgeTutorialsDelete } from '../../Service/KnowledgeTutorials/TutorialsServices';
import { useSelector } from 'react-redux';
import { ToasterError, ToasterSuccess } from '../../Service/CommonService';
import { ReactComponent as DeleteIconBlack } from "../../assets/Images/Icons/DeleteIconBlack.svg";
import Swal from "sweetalert2";

const Tutorials = () => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  let [tutorials, setTutorials]: any = React.useState({ IsData: false, data: [] });
  const [HandleTutorials, setHandleTutorials] = useState(false)
  const [tutorialID, settutorialID] = useState(null)

  const pageNav = [
    {
      name: 'Knowledge-Tutorials',
      link: '/knowledge-tutorials',
      active: true
    }
  ]

  const TutorialHeaders = React.useMemo(
    () => [
      // {
      //   Header: "Order Number",
      //   accessor: "SeqNo",
      // },
      {
        Header: "Title",
        accessor: "Title",
      },
      {
        Header: "URL",
        accessor: "URL",
      },

      {
        Header: "Action",
        accessor: "btns",
        className: "w-105 actionsort stickyth"
      },
    ],
    []
  );

  let TutorialData: any = [];
  tutorials.data.length &&
    tutorials.data.map((item) => {
      TutorialData.push({
        // SeqNo: item.SeqNo,
        Title: item.Title,
        URL: item.URL,
        btns: (
          <div className="d-flex">
            <Button className="table-btn-icon me-2 acteditbtn" onClick={() => {
              settutorialID(item.TutorialID)
              setHandleTutorials(true)
            }}>
            </Button>
            <Button className="btn-icon button btn actdelatebtn"
              onClick={() => {
                DeleteKnowledgeTutorials(item.TutorialID)
              }}
            >
              <DeleteIconBlack />
            </Button>
          </div>
        ),
      });
    });

  async function fetchTutorialsList() {
    setTutorials({ IsData: false, data: [] })
    let res: any = await GetKnowledgeTutorialsList(Token)
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setTutorials({ IsData: true, data: res.data.data.dataList1 })
        } else {
          setTutorials({ IsData: true, data: [] })
          ToasterError("Something Went Wrong")
        }
      } else {
        setTutorials({ IsData: true, data: [] })
        ToasterError("Something Went Wrong")
      }
    } else {
      setTutorials({ IsData: true, data: [] })
      ToasterError("Something Went Wrong")
    }
  }

  async function DeleteKnowledgeTutorials(tutsID) {
    Swal.fire({
      title: 'Are you sure want to delete ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input: any = {
          tutorialID: tutsID
        }
        let res: any = await KnowledgeTutorialsDelete(input, Token)
        if (res.data.success) {
          if (res.data !== undefined && res.data !== null) {
            if (res.data.data !== undefined && res.data.data !== null) {
              ToasterSuccess("Tutorial Deleted Successfully");
              fetchTutorialsList()
            } else {
              ToasterError("Something Went Wrong")
            }
          } else {
            ToasterError("Something Went Wrong")
          }
        } else {
          ToasterError("Something Went Wrong")
        }
      }
    });
  }

  useEffect(() => {
    fetchTutorialsList()
  }, [])




  return (
    <>
      <div>
        <InPageNav pageNav={pageNav} />
        <div className="student-and-expert d-flex">
          <div className="export-and-client d-flex mt-2">
            <div className="client">
              <button className="modalbutn" onClick={() => {
                // if (pageRights.is_create) {
                setHandleTutorials(true)
                // } else {
                //   CommonService.unauthorizedAction()
                // }
              }}>
                <i className="fa-regular fa-circle-plus"></i>
                <span>Add </span>
              </button>
            </div>
          </div>
        </div>
        <div className="tabelData endFlexCls">
          <TableView columnsData={TutorialHeaders} tableData={TutorialData} isData={tutorials.IsData} />
        </div>
      </div>

      {HandleTutorials &&
        <AddKnowledgeTutorial
          tutorialID={tutorialID}
          fetchTutorialsList={fetchTutorialsList}
          show={HandleTutorials}
          onHide={() => {
            settutorialID(null)
            setHandleTutorials(false)
          }}
        />
      }
    </>
  );
}

export default Tutorials