export const ENUMS: any = {
  actions: {
    UPDATE_USER: "UPDATE USER DETAILS",
    SET_IS_LOGGED: "SET_IS_LOGGED",
    SET_USER_DETAILS: "SET_USER_DETAILS",
    ACCESS_TOKEN: "ACCESS_TOKEN",
    SELECTED_SUBSCRIPTION: "SELECTED_SUBSCRIPTION",
    CLIENT_LIST: "CLIENT_LIST",
    SIDE_MENU_RIGHTS: "SIDE_MENU_RIGHTS",
    USER_RIGHTS: "USER_RIGHTS",
  },
  FromProperty: {
    LNR: "LNR",
    Group: "Group",
    MAC: "Meeting & Catering",
    ComboGroupMeeting: "Group & Meeting Combo",
  },
  side_menu: {
    dashboard: {
      title: "Dashboard",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1742_50)">
      <path d="M15 18.75C16.3807 18.75 17.5 17.6307 17.5 16.25C17.5 14.8693 16.3807 13.75 15 13.75C13.6193 13.75 12.5 14.8693 12.5 16.25C12.5 17.6307 13.6193 18.75 15 18.75Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.8125 14.4375L19.375 11.875" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 25.0001C6.17269 23.5477 4.84239 21.5628 4.19347 19.3206C3.54456 17.0785 3.60915 14.6899 4.37831 12.4861C5.14746 10.2822 6.58309 8.37221 8.48623 7.02069C10.3894 5.66918 12.6658 4.94312 15 4.94312C17.3342 4.94312 19.6106 5.66918 21.5138 7.02069C23.4169 8.37221 24.8525 10.2822 25.6217 12.4861C26.3908 14.6899 26.4554 17.0785 25.8065 19.3206C25.1576 21.5628 23.8273 23.5477 22 25.0001H8Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_1742_50">
      <rect width="30" height="30" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      `,
      child: [],
      link: "/",
      hasChild: false,
      moduleName: "Dashboard",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    clientList: {
      title: "Client List",
      Icon: `<i className="fa-light fa-user-group"></i>`,
      child: [],
      link: "/client-list",
      hasChild: false,
      moduleName: "Client List",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    portfolio: {
      title: "Portfolio",
      Icon: `<i className="fa-light fa-copy"></i>`,
      child: [],
      link: "/portfolio",
      hasChild: false,
      moduleName: "Portfolio",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    propertiesList: {
      title: "Hotels",
      Icon: `<i className="fa-light fa-building"></i>`,
      child: [],
      link: "properties-list",
      hasChild: false,
      moduleName: "Hotels",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    userSetup: {
      title: "User Setup",
      Icon: `<i className="fa-regular fa-user-clock"></i>`,
      child: [
        {
          title: "User",
          link: "/user-setup/user",
          moduleName: "User",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          Icon: `<i className="fa-solid fa-circle small-font"></i>`,
        },
        {
          title: "User Role",
          link: "/user-setup/user-role",
          moduleName: "User Role",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          Icon: `<i className="fa-solid fa-circle small-font"></i>`,
        },
      ],
      link: "#",
      hasChild: true,
    },
    SetupConfig: {
      title: "Setup & Configuration",
      Icon: `<i className="fa-light fa-gear"></i>`,
      child: [
        {
          title: "General Settings",
          link: "/general-settings",
          moduleName: "General Setting",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          Icon: `<i className="fa-solid fa-circle small-font"></i>`,
        },
        {
          title: "Mail Configuration",
          link: "/mail-setting",
          moduleName: "Mail Configuration",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          Icon: `<i className="fa-solid fa-circle small-font"></i>`,
        },
        {
          title: "Account Rules",
          link: "/accountrules",
          moduleName: "Account Rules",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          Icon: `<i className="fa-solid fa-circle small-font"></i>`,
        },
        // {
        //   title: "Market Segment",
        //   link: "/market-segment",
        //   moduleName: "Market Segment",
        //   is_view: false,
        //   is_create: false,
        //   is_update: false,
        //   is_delete: false,
        //   is_export: false,
        //   Icon: `<i className="fa-solid fa-circle small-font"></i>`,
        // },
        {
          title: "Additional Settings",
          link: "/additional-setting",
          moduleName: "Additional Setting",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          Icon: `<i className="fa-solid fa-circle small-font"></i>`,
        },
        {
          title: "Subscription Package List",
          link: "/subscription-package",
          moduleName: "Subscription Package",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          Icon: `<i className="fa-solid fa-circle small-font"></i>`,
        },
      ],
      link: "#",
      hasChild: true,
    },
  },
  MACTokens: [
    {
      tokenName: "Opportunity Name",
      tokenValue: "[$$opportunityname$$]",
    },
    {
      tokenName: "Account Name",
      tokenValue: "[$$accountname$$]",
    },

    { tokenName: "Contact", tokenValue: "[$$contact$$]" },
    { tokenName: "Contact Title", tokenValue: "[$$contacttitle$$]" },
    { tokenName: "Contact Name", tokenValue: "[$$contactname$$]" },
    { tokenName: "Address", tokenValue: "[$$address$$]" },
    { tokenName: "Contact City", tokenValue: "[$$contactcity$$]" },
    { tokenName: "Contact State", tokenValue: "[$$contactstate$$]" },
    { tokenName: "Contact Country", tokenValue: "[$$contactcountry$$]" },
    { tokenName: "Contact Zipcode", tokenValue: "[$$contactzipcode$$]" },
    { tokenName: "Email", tokenValue: "[$$email$$]" },
    { tokenName: "Onsite Contact", tokenValue: "[$$onsitecontact$$]" },
    { tokenName: "Start Date", tokenValue: "[$$startdate$$]" },
    { tokenName: "End Date", tokenValue: "[$$enddate$$]" },
    { tokenName: "Meeting Room Grid", tokenValue: "[$$meetingroomgrid$$]" },
    { tokenName: "Food Grid", tokenValue: "[$$foodgrid$$]" },
    { tokenName: "Beverage Grid", tokenValue: "[$$beveragegrid$$]" },
    { tokenName: "AV Misc Grid", tokenValue: "[$$avmiscgrid$$]" },
    { tokenName: "Special Instruction", tokenValue: "[$$specialinstruction$$]" },
    { tokenName: "Payment Method", tokenValue: "[$$paymentmethod$$]" },
    { tokenName: "Current Date", tokenValue: "[$$currentdate$$]" },
    { tokenName: "Food and Beverage Grid", tokenValue: "[$$foodandbeveragegrid$$]" },
    { tokenName: "Total Amount", tokenValue: "[$$totalamount$$]" },
    { tokenName: "Tax Amount", tokenValue: "[$$taxamount$$]" },
    { tokenName: "Tax Percentage", tokenValue: "[$$taxpercentage$$]" },
    { tokenName: "Gross Total", tokenValue: "[$$grosstotal$$]" },
    { tokenName: "Meeting Tax Detail Grid", tokenValue: "[$$meetingtaxdetailgrid$$]" },
    { tokenName: "AV Tax Detail Grid", tokenValue: "[$$avtaxdetailgrid$$]" },
    { tokenName: "Food Tax Detail Grid", tokenValue: "[$$foodtaxdetailgrid$$]" },
    { tokenName: "Beverage Tax Detail Grid", tokenValue: "[$$beveragetaxdetailgrid$$]" },
    { tokenName: "Meeting and AV Tax", tokenValue: "[$$meetingandavtax$$]" },
    { tokenName: "Food and Beverage tax", tokenValue: "[$$foodandbeveragetax$$]" },

    {
      tokenName: "Meeting Room Total",
      tokenValue: "[$$meetingroomtotal$$]",
    },
    {
      tokenName: "AV Total",
      tokenValue: "[$$avtotal$$]",
    },
    {
      tokenName: "Food Total",
      tokenValue: "[$$foodtotal$$]",
    },
    {
      tokenName: "Beverage Total",
      tokenValue: "[$$beveragetotal$$]",
    },
    {
      tokenName: "Meeting and AV Total",
      tokenValue: "[$$meetingandavtotal$$]",
    },
    {
      tokenName: "Food and Beverage Total",
      tokenValue: "[$$foodandbeveragetotal$$]",
    },
    {
      tokenName: "Meeting Room Tax",
      tokenValue: "[$$meetingroomtax$$]",
    },
    {
      tokenName: "AV Tax",
      tokenValue: "[$$avtax$$]",
    },
    {
      tokenName: "Food Tax",
      tokenValue: "[$$foodtax$$]",
    },
    {
      tokenName: "Beverage Tax",
      tokenValue: "[$$beveragetax$$]",
    },
    {
      tokenName: "Meeting and AV Tax",
      tokenValue: "[$$meetingandavtax$$]",
    },
    {
      tokenName: " Food and Beverage Tax",
      tokenValue: "[$$foodandbeveragetax$$]",
    },
    {
      tokenName: "Meeting Room Gross Total",
      tokenValue: "[$$meetingroomgrosstotal$$]",
    },
    {
      tokenName: "AV Gross Total",
      tokenValue: "[$$avgrosstotal$$]",
    },
    {
      tokenName: "Food Gross Total",
      tokenValue: "[$$foodgrosstotal$$]",
    },
    {
      tokenName: "Beverage Gross Total",
      tokenValue: "[$$beveragegrosstotal$$]",
    },
    {
      tokenName: "Meeting and AV Gross Total",
      tokenValue: "[$$meetingandavgrosstotal$$]",
    },
    {
      tokenName: "Food and Beverage Gross Total",
      tokenValue: "[$$foodandbeveragegrosstotal$$]",
    },
    {
      tokenName: "Meeting Room Tax Percentage",
      tokenValue: "[$$meetingroomtaxpercentage$$]",
    },
    {
      tokenName: "AV Tax Percentage",
      tokenValue: "[$$avtaxpercentage$$]",
    },
    {
      tokenName: "Food Tax Percentage",
      tokenValue: "[$$foodtaxpercentage$$]",
    },
    {
      tokenName: "Beverage Tax Percentage",
      tokenValue: "[$$beveragetaxpercentage$$]",
    },
    {
      tokenName: "Meeting and AV Tax Percentage",
      tokenValue: "[$$meetingandavtaxpercentage$$]",
    },
    {
      tokenName: "Food and Beverage Tax Percentage",
      tokenValue: "[$$foodandbeveragetaxpercentage$$]",
    },
  ],
};
