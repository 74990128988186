import axios from "axios";
import { AppConfigData } from "./CommonService";
import { Cookies } from "react-cookie-consent";
export const getAllMails = async (PageNo, NoofData, accessToken) => {
    try {
        const response = await axios({
            method: "POST",
            url: AppConfigData.APIBasePath + "Mail/api/Mail/GetAllMails?PageNo=" + PageNo + "&NoofData=" + NoofData + "",
            data: "",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + accessToken,
            },
        });
        return response;
    } catch (error: any) {
        let data: any = null
        if (error.response.status === 401) {
            data = {
                data: {
                    success: false,
                    StatusCode: "401",
                    Message: "Session has been expired.",
                },
            };
            Cookies.remove("SystemSettingDate");
            Cookies.remove("SystemSettingTime");
            // caches.keys().then((names) => {
            //     names.forEach((name) => {
            //         caches.delete(name);
            //     });
            // });
            localStorage.clear()
            window.location.pathname = '/'
        } else {
            data = {
                data: {
                    success: false,
                    StatusCode: "02",
                    Message: "Something Wrong in API",
                },
            };
        }
        return data;
    }
};


export const getMailDetails = async (Index, MessageId, accessToken) => {
    try {
        const response = await axios({
            method: "POST",
            url: AppConfigData.APIBasePath + "Mail/api/Mail/GetMailDetails?Index=" + Index + "&MessageId=" + MessageId + "",
            data: "",
            headers: {
                /*"Access-Control-Allow-Origin": "*",*/
                "accept": "text/plain",
                /*"Content-Type": "application/json; charset=utf-8",*/
                Authorization: "bearer " + accessToken,
            },
        });
        return response;
    } catch (error: any) {
        let data: any = null
        if (error.response.status === 401) {
            data = {
                data: {
                    success: false,
                    StatusCode: "401",
                    Message: "Session has been expired.",
                },
            };
            Cookies.remove("SystemSettingDate");
            Cookies.remove("SystemSettingTime");
            // caches.keys().then((names) => {
            //     names.forEach((name) => {
            //         caches.delete(name);
            //     });
            // });
            localStorage.clear()
            window.location.pathname = '/'
        } else {
            data = {
                data: {
                    success: false,
                    StatusCode: "02",
                    Message: "Something Wrong in API",
                },
            };
        }
        return data;
    }
};
