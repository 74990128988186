
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ToasterError, ToasterSuccess } from '../../Service/CommonService';
import { ContactFileUpload, ContactImportSave } from '../../Service/ImportService';
import SpinnerIcon from "../../../src/assets/Images/Icons/Spinner-0.8s-50px.svg";
const ContactFileUploadModal = (props) => {

  const dataSelector: any = useSelector((state: any) => state.session);
  const ClientDropdown = dataSelector.ClientListDropdown || [];
  const [FileUploadbtn, setFileUploadbtn] = useState(false)
  let [UploadFileResponse, setUploadFileResponse]: any = React.useState([]);
  let [UploadFileColumnResponse, setUploadFileColumnResponse]: any = React.useState([]);
  let [Spinner, setSpinner]: any = React.useState(false);
  const Token = dataSelector.access_Token;
  let ClientLabel: any = localStorage.getItem('SelectedClientLabel');
  let ClientID = localStorage.getItem('SelectedClientID');
  const [AttachFiles, setAttachFiles]: any = useState({
    name: "",
    link: [],
    errors: "",
  });
  let options: any = [{
    value: null,
    label: "Select Client",
    color: ""
  }];
  ClientDropdown.map((item: any) => {
    options.push({
      value: item.clientID,
      label: item.clientName,
      color: "",
    });
  });

  async function Upload() {
    setAttachFiles({ ...AttachFiles, ["errors"]: "" });
    let Type = AttachFiles.link[0].name.split(".");
    if (Type[1] === "xlsx" || Type[1] === "xls") {
      let formData: any = new FormData();
      formData.append("ClientID", ClientID);
      formData.append("ExcelFile", AttachFiles.link[0]);
      setSpinner(true)
      setFileUploadbtn(true)
      let Res: any = await ContactFileUpload(formData, Token);
      setFileUploadbtn(false)
      if (Res.data.success) {
        if (Res.data !== undefined && Res.data !== null) {
          if (Res.data.data !== undefined && Res.data.data !== null) {
            setSpinner(false)
            if (Res.data.data.statusCode == 204) {
              ToasterError("Column Not Match");
            }
            else {
              setUploadFileResponse(Res.data.data.datatable)
              setUploadFileColumnResponse(Res.data.data.dataColumnName)
            }
          }
        } else {
          ToasterError("Responce not Found")
        }
      }
    } else {
      ToasterError("Please select XLSX  or XLS file.")
    }

  }

  async function SaveImport() {
    let formData: any = new FormData();
    formData.append("clientID", ClientID);
    formData.append("excelFile", AttachFiles.link[0]);
    setSpinner(true)
    let Res: any = await ContactImportSave(formData, Token);
    if (Res.data.success) {
      if (Res.data !== undefined && Res.data !== null) {
        setSpinner(false)
        if (Res.data.statusCode === 200) {
          ToasterSuccess("File Imported Successfully");
          setUploadFileColumnResponse([])
          props.onHide(false);
        }
        else {
          ToasterError(Res.data.message);
        }
      }
    }
  }

  function chooseFile(e: any) {
    setAttachFiles({ ...AttachFiles, ["link"]: e.target.files })
    setFileUploadbtn(true)
  }
  const handleClose = () => {
    setUploadFileResponse([])
    setUploadFileColumnResponse([])
    props.onHide(false);
  }
  return (
    <Modal
      {...props}
      className="clientpoupadd"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Upload Contact File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/*  */}
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flxcolum">
                <div className="export me-3 myheightfix selectheader">
                  <Form.Group>
                    <Form.Label>Client Name</Form.Label>
                    <Form.Control type="text" maxLength={256}
                      value={ClientLabel} disabled
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="client myheightfix" >
                      <Form.Label></Form.Label>

                      <input
                        type="file"
                        className={`form-control  ${AttachFiles.errors && "invalid"}`}
                        multiple
                        onChange={chooseFile}
                      />
                      <span className="Validationtext"> Please select XLSX  or XLS file. </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="client myheightfix" >
                      <Form.Label></Form.Label>
                      <Button role="button" className="savebtnclient"
                        tabIndex={-1}
                        onClick={() => Upload()}
                        onKeyDown={() => Upload()}
                        disabled={FileUploadbtn === false ? true : false}
                      >
                        <i className="fa-solid fa-file-arrow-up me-2"
                        ></i>File Upload
                      </Button>
                    </div>
                  </div>
                  <a className='dwnlink' href='https://inntelligentcrmadmin.softcube.in/Assets/TemplateFiles/HGWNDHW_Contacts_Import.xlsx' download>Download Template</a>

                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
            </div>
          </div>
          {/*  */}
          <div className="row mt-5">
            <h5>Preview Data :</h5>
            <div className="tabelData">
              {Spinner === false ?
                <div className="table-responsive">
                  {
                    UploadFileColumnResponse.length > 0 &&
                    UploadFileColumnResponse !== undefined &&
                    <table role="table" className="table withoutsearch">
                      <thead>
                        <tr role="row">
                          {UploadFileColumnResponse.length > 0 &&
                            UploadFileColumnResponse !== undefined &&
                            UploadFileColumnResponse.map((item: any) => (
                              <>
                                <th title="Toggle SortBy" ><span className="d-flex align-items-center">{item.toUpperCase}<span className="text-sort-icon"></span></span></th>
                              </>
                            ))
                            // : "No File Preview" 
                          }

                        </tr>
                      </thead>
                      <tbody>
                        {UploadFileResponse.length > 0 &&
                          UploadFileResponse !== undefined && UploadFileColumnResponse.length > 0 &&
                          UploadFileColumnResponse !== undefined &&
                          UploadFileResponse.map((row: any, index) => (
                            <tr key={index}>
                              {
                                UploadFileColumnResponse.map((item: any) => (
                                  <>
                                    <td >{row[item.toLowerCase()]}</td>
                                  </>
                                ))
                              }
                            </tr>
                          ))}

                      </tbody>
                    </table>
                  }
                </div>
                :
                < img
                  src={SpinnerIcon}
                  alt="../assets/Images"
                />
              }
            </div>
          </div>
          <div className="bottommargin"></div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="savebtnclient"
          role="button"
          tabIndex={-1}
          onClick={() => SaveImport()}
          onKeyDown={() => SaveImport()}
          disabled={UploadFileColumnResponse.length > 0 &&
            UploadFileColumnResponse !== undefined ? false : true}
        >
          <i className="fa-solid fa-rotate me-2"></i>Import File
        </Button>
      </Modal.Footer>
    </Modal >
  );
}

export default ContactFileUploadModal