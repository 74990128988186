import { Form, FormControl, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LoginButtonIcon from "../../../src/assets/Images/Icons/LoginButtonIcon.svg";
import LoginUserIcon from "../../../src/assets/Images/Icons/LoginUserIcon.svg";
import { useState } from "react";
import { ReactComponent as LoginLogo } from "../../../src/assets/Images/LoginPage/loginLogo.svg";
import { ToasterError, ToasterSuccess, fnCheckValidationOfObject } from "../../Service/CommonService";
import { SendAdminUserPasswordEmailForgotPassword } from "../../Service/ForgotPassword/ForgotPasswordServices";

function ForgotPass() {

  let navigate = useNavigate()
  const [IsloginClick, setIsloginClick] = useState(false);

  const [userData, setUserData] = useState({
    emailID: "",
    errors: {
      emailID: "",
      ValidationRules: [
        {
          FieldName: "emailID",
          ValidationType: "email",
          ValidationMessage: "Enter Valid Email address.",
        },
        {
          FieldName: "emailID",
          ValidationType: "required",
          ValidationMessage: "Email is required.",
        },
      ],
    },
  })

  const getPassword = async () => {

    let obj = fnCheckValidationOfObject(userData);
    if (!obj.isValid) {
      setUserData({
        ...obj.obj,
      });
    }
    if (obj.isValid) {
      setIsloginClick(true)
      let input: any = {
        emailID: userData.emailID
      }
      let res: any = await SendAdminUserPasswordEmailForgotPassword(input)
      debugger
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.statusCode === 200) {
            ToasterSuccess(res.data.message)
          } else if (res.statusCode === 204) {
            ToasterError(res.data.message);
          } else {
            ToasterError(res.data.message);
          }
        } else {
          ToasterError(res.data.message);
        }
      } else {
        ToasterError("Something went wrong.");
      }
      setIsloginClick(false)
    } else {
      ToasterError(userData.errors.emailID)
    }
  }


  return (
    <>

      <div className="d-flex">
        <div className="innerSecLogin">
          <div className="loginLogo d-flex justify-content-center">
            <span>
              <LoginLogo />
            </span>
          </div>
          <div className="loginHead">
            <h1>Forgot Password</h1>
          </div>

          <Form>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <span>
                  <img src={LoginUserIcon} alt="" />
                </span>
              </InputGroup.Text>
              <FormControl
                type="email"
                placeholder="Enter Registered E-mail"
                aria-label="Enter Registered E-mail"
                aria-describedby="basic-addon1"
                onChange={(e) => setUserData({
                  ...userData,
                  emailID: e.target.value
                })}
              />
            </InputGroup>
            <Form.Group className="form-button d-flex ">
              {IsloginClick === false ? (
                <button
                  onClick={getPassword}
                  className="btn btn-primary mylgnbtn" type="button">
                  <span className="me-3">
                    {/* <img src={LoginButtonIcon} alt="" /> */}
                  </span>
                  Verify
                </button>
              ) : (
                <button className="btn btn-primary mylgnbtn" type="button">
                  <span className="me-3">
                    <i className="fa-duotone fa-spinner fa-spin"></i>
                  </span>
                  Verifying...
                </button>
              )}
              <button
                onClick={() => navigate("/")}
                className="btn btn-primary mylgnbtn ms-3" type="button">
                <span className="me-3">
                  <img src={LoginButtonIcon} alt="" />
                </span>
                Back
              </button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
}

export default ForgotPass;
