import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import TableView from "../../Component/Table/Index";
import { SubscriptionTypeList } from "../../Service/ClientService";
import { SubsciptionList } from "../../Service/SubscriptionService";
import InPageNav from "../Common/InPageNav/Index";
import AddSubscriptionPakageModal from "../Model/AddSubscriptionPakageModal";
import { CommonService, GetPageRight } from "../../Service/CommonService";

function SubscriptionPackage() {
  let pageRights = GetPageRight('Subscription Package')
  const [modalShowsubscriptionpakage, setModalShowSubscriptionPakage] = React.useState(false);
  const [, setModalShowSubscription] = React.useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;


  // const ClientDropdown = dataSelector.ClientListDropdown || [];
  const subscriptionTableHeader = React.useMemo(
    () => [
      {
        Header: "Subscription Name",
        accessor: "subscriptionName",
        className: "w-300"
      },
      {
        Header: "Hotels",
        accessor: "hotels",
        className: "center w-300"
      },
      {
        Header: "Users",
        accessor: "users",
        className: "center w-185"
      },
      {
        Header: "Price Per Month",
        accessor: "priceMonth",
        className: "center w-185"
      },
      {
        Header: "Price Per Year",
        accessor: "priceYear",
        className: "center w-185"
      },
      {
        Header: "Action",
        accessor: "action",
        className: "w-135 actionsort stickytd"
      },
    ],
    []
  );

  // let ClientLabel = localStorage.getItem('SelectedClientLabel');
  // let ClientID = localStorage.getItem('SelectedClientID');

  let [SubscriptionList, setSubscriptionList]: any = React.useState({ IsData: false, data: [] });

  const [SubscriptionDropdownList, setSubscriptionDropdownList] = React.useState([]);
  let [GetSubscriptionByID, setGetSubscriptionByID] = React.useState([]);

  const pageNav = [
    {
      name: 'Subscription Package',
      link: '/subscription-package',
      active: true
    }
  ]

  // let options: any = [{
  //   value: null,
  //   label: "Select Client",
  //   color: "",
  // }];
  // ClientDropdown.map((item) => {
  //   options.push({
  //     value: item.clientID,
  //     label: item.clientName,
  //     color: "",
  //   });
  // });

  let subscriptionTableData: any = [];
  SubscriptionList.data.length !== undefined &&
    SubscriptionList.data.length !== null &&
    SubscriptionList.data.length !== 0 &&
    SubscriptionList.data.map((item: any) => {
      subscriptionTableData.push({
        className: {
          action: "stickytd"
        },
        subscriptionName: item.name,
        hotels: (
          <div className="text-center">{item.noOfProperties} </div>
        ),

        users: (
          <div className="text-center"> {item.noOfUser} </div>
        ),


        priceMonth: (
          <div className="text-center"> {item.pricePerMonth}</div>
        ),

        priceYear: (
          <div className="text-center"> {item.pricePerYear} </div>
        ),
        action: (
          <span className="btn-icon-grp-table justify-content-end  align-items-center">
            <Button className="btn-icon button btn btn-primary editIcon acteditbtn" onClick={() => {

              if (pageRights.is_update) {
                EditSubscription(item)
              } else {
                CommonService.unauthorizedAction()
              }
            }}></Button>
          </span>
        ),
      });
    });

  React.useEffect(() => {
    // getSubsciptionListData();
    getSubsciptionList();
  }, []);


  async function EditSubscription(subscription) {
    let inputs = {
      subscriptionID: subscription.subscriptionID
    }
    let resClientList: any = await SubscriptionTypeList(inputs, Token);
    if (resClientList.data.success) {
      if (resClientList.data.data !== null && resClientList.data.data !== undefined) {
        setGetSubscriptionByID(resClientList.data.data)
        setModalShowSubscriptionPakage(true)
      }
    }
    //pass value for popup identify
    setModalShowSubscription(true)
  }

  // async function getSubsciptionListData() {
  //   let input = {
  //     clientID: null
  //   };

  //   setSubscriptionList({ IsData: false, data: [] });

  //   const res: any = await SubsciptionList(input, Token);

  //   if (res.data.success) {
  //     if (res.data !== null && res.data !== undefined) {
  //       if (res.data.data !== undefined && res.data.data !== null) {
  //         if (res.data.data.clientSubscription !== undefined && res.data.data.clientSubscription !== null) {
  //           setSubscriptionList({ IsData: true, data: res.data.data.clientSubscription });
  //         }
  //       }
  //     }
  //   }
  // }

  async function getSubsciptionList() {

    let input: any = {
      subscriptionID: null,
    }
    setSubscriptionList({ IsData: false, data: [] });
    const res: any = await SubscriptionTypeList(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data !== undefined) {
          if (res.data.data !== null && res.data.data !== undefined) {
            if (res.data.data.subscription !== null && res.data.data.subscription !== undefined) {
              // setSubscriptionDropdownList(res.data.data.subscription);
              setSubscriptionList({ IsData: true, data: res.data.data.subscription });
            }
          }
        }
      }
    }
  }

  return (
    <>
      <div>
        <InPageNav pageNav={pageNav} />
        <div className="student-and-expert d-flex">
          <div className="export-and-client d-flex mt-2">
            <div className="client">
              <button className="modalbutn"
                onClick={() => {
                  if (pageRights.is_create) {
                    setModalShowSubscriptionPakage(true)

                  } else {
                    CommonService.unauthorizedAction()
                  }
                }}
              // onClick={() => setModalShowSubscription(true)}
              >
                <i className="fa-regular fa-circle-plus"></i>
                <span>Subscription Package</span>
              </button>
              {/* } */}


            </div>
          </div>

        </div>
        <div className="tabelData endFlexCls">
          <TableView columnsData={subscriptionTableHeader} tableData={subscriptionTableData} isData={SubscriptionList.IsData} />
        </div>
      </div>

      {/* {
        modalShowsubscriptionpakage == true && */}
      <AddSubscriptionPakageModal
        show={modalShowsubscriptionpakage}
        onHide={() => {
          setModalShowSubscriptionPakage(false)
          setGetSubscriptionByID([])
        }}
        getSubsciptionList={getSubsciptionList}
        GetSubscriptionByID={GetSubscriptionByID}
      />
      {/* } */}
    </>
  );
}

export default SubscriptionPackage;
