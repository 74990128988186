import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CommonService, ToasterError, ToasterSuccess, fnCheckValidationOfObject } from '../../Service/CommonService';
import { InsertSubscriptionPackageData, UpdateSubscriptionPackage } from '../../Service/SubscriptionService';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export const AddSubscriptionPakageModal = (props) => {

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const userID = dataSelector.auth_UserDetail.userID;
  const [FileBanner, setFileBanner]: any = React.useState(null);
  const [fileThumbnail, setfileThumbnail]: any = React.useState(null);
  const [OnProcessHide, setOnProcessHide] = React.useState(false);

  const initials: any = {
    subscriptionID: "",
    SubscriptionName: "",
    ShortDescription: "",
    Description: "",
    URL: "",
    PricePerMonth: "",
    PricePerYear: "",
    Details: "",
    NOofUsers: 0,
    NOofProperties: "",
    NOofAccounts: 0,
    BannerImage: "",
    ThumbnailImage: "",
    updateBy: userID,
    errors: {
      SubscriptionName: "",
      PricePerMonth: "",
      PricePerYear: "",
      NOofProperties: "",
      ValidationRules: [
        {
          FieldName: "SubscriptionName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "PricePerMonth",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "PricePerMonth",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Between {0} And {15} character",
        },
        {
          FieldName: "PricePerYear",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Between {0} And {15} character",
        },
        {
          FieldName: "PricePerYear",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "NOofProperties",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "NOofProperties",
          ValidationType: "minmax",
          ValidationMessage: "Enter Valid Between {0} And {1}",
          Min: 0,
          Max: 999
        },
      ],
    },
  }
  const [SaveSubscriptionPackage, setSaveSubscriptionPackage]: any = React.useState(initials)
  const [AttachFilesBanner, setAttachFilesBanner]: any = React.useState({
    name: "",
    link: [],
    errors: "",
  });
  const [AttachFilesThubnail, setAttachFilesThubnail]: any = React.useState({
    name: "",
    link: [],
    errors: "",
  });
  React.useEffect(() => {
    setSaveSubscriptionPackage(initials);
    if (props.GetSubscriptionByID) {
      if (Object.keys(props.GetSubscriptionByID).length) {
        setSaveSubscriptionPackage(
          {
            ...SaveSubscriptionPackage,
            subscriptionID: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].subscriptionID,
            SubscriptionName: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].name,
            ShortDescription: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].shortDescription,
            Description: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].description,
            URL: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].landingPageURL,
            PricePerMonth: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].pricePerMonth,
            PricePerYear: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].pricePerYear,
            Details: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].description,
            NOofUsers: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].noOfUser,
            NOofProperties: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].noOfProperties,
            NOofAccounts: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].noOfAccounts || 0,
            BannerImage: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].bannerImage,
            ThumbnailImage: props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].thumbnailImage,
            updateBy: userID,
          })
        setFileBanner(props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].bannerImage)
        setfileThumbnail(props.GetSubscriptionByID && props.GetSubscriptionByID.subscription[0].thumbnailImage)
      }
    }
  }, [props.GetSubscriptionByID]);
  function chooseBannerFile(e: any) {
    setAttachFilesBanner({ ...AttachFilesBanner, ["link"]: e.target.files })
    const fileLoaded: any = URL.createObjectURL(e.target.files[0]);
    setFileBanner(fileLoaded);
  }
  function chooseThumbnailFile(e: any) {
    setAttachFilesThubnail({ ...AttachFilesThubnail, ["link"]: e.target.files })
    const fileLoaded: any = URL.createObjectURL(e.target.files[0]);
    setfileThumbnail(fileLoaded)
  }
  async function SaveSubscription() {

    let obj = fnCheckValidationOfObject(SaveSubscriptionPackage);
    setSaveSubscriptionPackage({
      ...obj.obj,
    });
    if (obj.isValid) {
      if (SaveSubscriptionPackage.subscriptionID) {
        // Update
        let formData: any = new FormData();
        formData.append("SubscriptionID", SaveSubscriptionPackage.subscriptionID);
        formData.append("IsActive", true);
        formData.append("Name", SaveSubscriptionPackage.SubscriptionName);
        formData.append("ShortDescription", SaveSubscriptionPackage.ShortDescription);
        formData.append("Description", SaveSubscriptionPackage.Description);
        formData.append("LandingPageURL", SaveSubscriptionPackage.URL);
        formData.append("PricePerMonth", SaveSubscriptionPackage.PricePerMonth);
        formData.append("PricePerYear", SaveSubscriptionPackage.PricePerYear);
        formData.append("NoOfUser", SaveSubscriptionPackage.NOofUsers);
        formData.append("NoOfProperties", SaveSubscriptionPackage.NOofProperties);
        formData.append("NoOfAccounts", SaveSubscriptionPackage.NOofAccounts);
        formData.append("UpdatedBy", SaveSubscriptionPackage.updateBy);
        formData.append("BannerImageFile", AttachFilesBanner.link[0] === undefined ? null : AttachFilesBanner.link[0]);
        formData.append("ThumbnailImageFile", AttachFilesThubnail.link[0] === undefined ? null : AttachFilesThubnail.link[0]);
        formData.append("Status_Term", "Active");
        formData.append("BannerImage", "");
        formData.append("ThumbnailImage", "");
        let responce: any = await UpdateSubscriptionPackage(formData, Token);
        if (responce.data.success) {
          if (responce.data !== undefined) {
            if (responce.data.statusCode === 200) {
              handleClose();
              ToasterSuccess("Subscription Package Updated Succesfully");
              if (props) {
                props.getSubsciptionList();
              }
            } else {
              ToasterError(responce.data.message);
            }
          } else {
            ToasterError("Something went wrong.");
          }
        } else {
          ToasterError("Something went wrong.");
        }
      }
      else {
        // ADD :
        let formData: any = new FormData();
        formData.append("Name", SaveSubscriptionPackage.SubscriptionName);
        formData.append("ShortDescription", SaveSubscriptionPackage.ShortDescription);
        formData.append("Description", SaveSubscriptionPackage.Description);
        formData.append("LandingPageURL", SaveSubscriptionPackage.URL);
        formData.append("PricePerMonth", SaveSubscriptionPackage.PricePerMonth);
        formData.append("PricePerYear", SaveSubscriptionPackage.PricePerYear);
        formData.append("NoOfUser", SaveSubscriptionPackage.NOofUsers);
        formData.append("NoOfProperties", SaveSubscriptionPackage.NOofProperties);
        formData.append("NoOfAccounts", SaveSubscriptionPackage.NOofAccounts);
        formData.append("updateBy", SaveSubscriptionPackage.updateBy);
        formData.append("BannerImageFile", AttachFilesBanner.link[0]);
        formData.append("ThumbnailImageFile", AttachFilesThubnail.link[0]);
        formData.append("Status_Term", "Active");
        formData.append("BannerImage", "");
        formData.append("ThumbnailImage", "");
        setOnProcessHide(true)
        let responce: any = await InsertSubscriptionPackageData(formData, Token);
        setOnProcessHide(false)
        if (responce.data.success) {
          if (responce.data !== undefined) {
            if (responce.data.statusCode === 200) {
              handleClose();
              ToasterSuccess("Subscription Package Added Succesfully");
              if (props) {
                props.getSubsciptionList();
              }
            } else {
              ToasterError(responce.data.message);
            }
          } else {
            ToasterError("Something went wrong.");
          }
        } else {
          ToasterError("Something went wrong.");
        }
      }
    }
  }
  const handleClose = () => {
    setFileBanner(null)
    setfileThumbnail(null)
    setSaveSubscriptionPackage(initials);
    props.onHide(false);
  }


  return (
    <Modal
      {...props}
      className="clientpoupadd"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>

        {
          !SaveSubscriptionPackage.subscriptionID ?
            <Modal.Title id="contained-modal-title-vcenter">Add Subscription Package</Modal.Title> :
            <Modal.Title id="contained-modal-title-vcenter">Edit Subscription Package</Modal.Title>
        }


      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <Form.Group>
                <Form.Label>Subscription Name*</Form.Label>
                <Form.Control type="text" maxLength={66} placeholder="Subscription Name"
                  value={SaveSubscriptionPackage.SubscriptionName}
                  onChange={(e) => setSaveSubscriptionPackage({
                    ...SaveSubscriptionPackage,
                    ["SubscriptionName"]: e.target.value
                  })}
                  isInvalid={SaveSubscriptionPackage.errors.SubscriptionName}
                />
                {SaveSubscriptionPackage.errors.SubscriptionName && (
                  <Form.Control.Feedback type="invalid">
                    {SaveSubscriptionPackage.errors.SubscriptionName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6">
              <Form.Group>
                <Form.Label>URL</Form.Label>
                <Form.Control type="text" maxLength={270} placeholder="URL"
                  value={SaveSubscriptionPackage.URL}
                  onChange={(e) => setSaveSubscriptionPackage({
                    ...SaveSubscriptionPackage,
                    ["URL"]: e.target.value
                  })} />
              </Form.Group>
            </div>

          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <Form.Group>
                <Form.Label>Price Per Month*</Form.Label>
                <Form.Control type="number" placeholder="Price Per Month"
                  onWheel={(event: any) => { event.target.blur() }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode == '38' || e.keyCode == '40') {
                      e.stopPropagation()
                      e.preventDefault()
                    }
                  }}
                  value={SaveSubscriptionPackage.PricePerMonth}
                  onChange={(e) => setSaveSubscriptionPackage({
                    ...SaveSubscriptionPackage,
                    ["PricePerMonth"]: e.target.value
                  })}
                  isInvalid={SaveSubscriptionPackage.errors.PricePerMonth}
                />
                {SaveSubscriptionPackage.errors.PricePerMonth && (
                  <Form.Control.Feedback type="invalid">
                    {SaveSubscriptionPackage.errors.PricePerMonth}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <Form.Group>
                <Form.Label>Price Per Year*</Form.Label>
                <Form.Control type="number" maxLength={256} placeholder="Price Per Year"
                  onWheel={(event: any) => { event.target.blur() }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode == '38' || e.keyCode == '40') {
                      e.stopPropagation()
                      e.preventDefault()
                    }
                  }}
                  value={SaveSubscriptionPackage.PricePerYear}
                  onChange={(e) => setSaveSubscriptionPackage({
                    ...SaveSubscriptionPackage,
                    ["PricePerYear"]: e.target.value
                  })}
                  isInvalid={SaveSubscriptionPackage.errors.PricePerYear}
                />
                {SaveSubscriptionPackage.errors.PricePerYear && (
                  <Form.Control.Feedback type="invalid">
                    {SaveSubscriptionPackage.errors.PricePerYear}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <Form.Group>
                <Form.Label>No.of Properties*</Form.Label>
                <Form.Control type="number" placeholder="No.of Properties"
                  onWheel={(event: any) => { event.target.blur() }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode == '38' || e.keyCode == '40') {
                      e.stopPropagation()
                      e.preventDefault()
                    }
                  }}
                  value={SaveSubscriptionPackage.NOofProperties}
                  onChange={(e) => setSaveSubscriptionPackage({
                    ...SaveSubscriptionPackage,
                    ["NOofProperties"]: e.target.value
                  })}
                  isInvalid={SaveSubscriptionPackage.errors.NOofProperties}
                />
                {SaveSubscriptionPackage.errors.NOofProperties && (
                  <Form.Control.Feedback type="invalid">
                    {SaveSubscriptionPackage.errors.NOofProperties}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group>
                <Form.Label>Short Description</Form.Label>
                <Form.Control as="textarea" style={{ height: '80px' }} maxLength={325} placeholder=""
                  value={SaveSubscriptionPackage.ShortDescription}
                  onChange={(e) => setSaveSubscriptionPackage({
                    ...SaveSubscriptionPackage,
                    ["ShortDescription"]: e.target.value
                  })} />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group>
                <Form.Label>Long Description</Form.Label>
                <CKEditor
                  editor={Editor}
                  config={{
                    extraPlugins: [CommonService.uploadPlugin]
                  }}
                  data={SaveSubscriptionPackage.Description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setSaveSubscriptionPackage({
                      ...SaveSubscriptionPackage,
                      ["Description"]: data
                    })
                  }}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-xl-3 col-lg-6 col-md-6">
              <h2 className="paymenttitle">Banner Image</h2>
              <div className="custom-file comaninputfile">
                {
                  FileBanner &&
                  <img src={FileBanner} style={{
                    display: 'flex',
                    border: '2px solid tomato',
                    maxWidth: '300px',
                    maxHeight: '300px',
                  }}
                    alt="" />}
              </div>
              <div className="comninputbtn">
                <span>Select Banner Image</span>
                <input type="file" accept='image/*' id="inputGroupFile01" className="" onChange={chooseBannerFile} />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <h2 className="paymenttitle">Thumbnail Image</h2>
              <div className="custom-file comaninputfile">
                {
                  fileThumbnail &&
                  <img src={fileThumbnail} style={{
                    display: 'flex',
                    border: '2px solid tomato',
                    maxWidth: '300px',
                    maxHeight: '300px',
                  }}
                    alt="" />}
              </div>
              <div className="comninputbtn">
                <span>Select Thumbnail Image</span>
                <input type="file" accept='image/*' id="inputGroupFile01" className="" onChange={chooseThumbnailFile} />
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="savebtnclient" onClick={() => SaveSubscription()}
          disabled={OnProcessHide === true ? true : false}
        >
          <i className="fa-solid fa-floppy-disk me-2"></i>Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddSubscriptionPakageModal;
