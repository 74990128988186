/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent, SubMenu
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
// import Dashboard from "../assets/Images/Icons/dashboard.svg";
import { ReactComponent as Dashboard } from '../assets/Images/Icons/dashboard.svg';
import { ENUMS } from "../Constants";
const parse = require('html-react-parser');
import { FcDataConfiguration } from "react-icons/fc";
import { TbMessageChatbot } from "react-icons/tb";
function DefaultSidebar({ collapsed, toggled, handleToggleSidebar }) {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const dataSelector: any = useSelector((state: any) => state.session);
  let sideMenuObject = dataSelector.sidemenu_rights

  const navigate = useNavigate();
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const routedActivation = {
    dashboard: ["/"],
    clientList: ["/client-list"],
    portfolio: ["/portfolio"],
    propertiesList: ["/properties-list"],
    // subscriptionList: ["/subscription-list"],
    suspect: ["/suspects", "/suspect/open", "/suspect/nonViable", "/suspect/openActivities"],
    imports: [
      "/Imports",
      // "/import/accounts",
      // "/import/contacts",
      // "/import/activities",
      // "/import/suspects",
    ],
    userSetup: [
      "/user-setup/user",
      "/user-setup/user-role",
    ],
    SetupConfig: [
      "/subscription-package",
      "/additional-setting",
      "/accountrules",
      "/market-segment",
      "/general-settings"
    ],
  };

  const setActiveFun = () => {
    const routeKeys = Object.keys(routedActivation);
    const childClass = document.getElementsByClassName("sub-menu-active");
    const menuActiveClass = document.getElementsByClassName("menuActive");
    if (menuActiveClass.length) {
      menuActiveClass[0].classList.remove("menuActive");
    }
    if (childClass.length) {
      childClass[0].classList.remove("sub-menu-active");
    }
    routeKeys.forEach((routeitem) => {
      if (routedActivation[routeitem].includes(location.pathname)) {
        const parentDiv = document.getElementById(`${routeitem}`);
        if (parentDiv?.classList.contains("main-menu-title")) {
          const proInnerItem = parentDiv.getElementsByClassName("pro-inner-item");
          proInnerItem[0].classList.add("sub-menu-active");
        } else {
          if (parentDiv != null) {
            parentDiv.classList.add("sub-menu-active");
          }
        }
        return false;
      }
    });
  };

  useEffect(() => {
    setActiveFun();
  });
  function closeNav() {
    let element: any = document.getElementById("mySidebar");
    element.classList.remove("mystyle");
  }

  const SubMenuComponent = ({ sideChildKey, parentIt }) => {
    let isCanShow = false;
    parentIt.child.forEach((ch_ck_item) => {
      if (!isCanShow) {
        isCanShow = ch_ck_item.is_view || ch_ck_item.is_create || ch_ck_item.is_update || ch_ck_item.is_delete || ch_ck_item.is_export
      }
    })
    if (isCanShow) {
      return (<SubMenu
        id={sideChildKey}
        key={sideChildKey}
        defaultOpen={
          parentIt.hasChild
            ? parentIt.child.filter((obj) => {
              if (obj.hasChild) {
                if (
                  obj.child.filter((iner_obj) => iner_obj.link == window.location.pathname)
                    .length
                ) {
                  return obj;
                }
              } else {
                if (obj.link == window.location.pathname) {
                  return obj;
                }
              }
            }).length
              ? true
              : false
            : false
        }
        className="main-menu-title"
        icon={parse(parentIt.Icon)}
        title={parentIt.title}
      >
        {
          parentIt.child.map((child_item, i) => {
            if (child_item.is_view || child_item.is_create || child_item.is_update || child_item.is_delete || child_item.is_export) {
              if (child_item.title !== "General Settings") {
                return (<NavLink
                  onClick={(e) => {
                    setActiveFun();
                  }}
                  key={i}
                  to={child_item.link}
                >
                  <MenuItem icon={parse(child_item.Icon)}>{child_item.title}</MenuItem>
                </NavLink>)
              }
            } else {
              return <></>
            }

          })}

      </SubMenu>)
    } else {
      return (<></>)
    }

  }

  return (
    <>
      <aside className="main-sidebar" id="mySidebar">
        <div className="left-sidebar">
          <div className="second-panel-sidebar">
            <button className="closebtn" onClick={closeNav}><i className="fa-regular fa-xmark"></i></button>

            <ProSidebar collapsed={collapsed}
              toggled={toggled}
              breakPoint="md"
              onToggle={handleToggleSidebar}>
              <SidebarContent>
                <Menu>
                  {Object.keys(sideMenuObject) !== null && Object.keys(sideMenuObject).map((side_key) => {

                    return (!sideMenuObject[side_key].hasChild ?
                      sideMenuObject[side_key].is_view || sideMenuObject[side_key].is_create || sideMenuObject[side_key].is_update || sideMenuObject[side_key].is_delete || sideMenuObject[side_key].is_export
                        ?
                        <NavLink
                          id={side_key}
                          key={side_key}
                          to={sideMenuObject[side_key].link}
                          onClick={(e) => {
                            setActiveFun();
                          }}
                        >
                          {
                            sideMenuObject[side_key].title !== "Import" &&
                            <MenuItem icon={parse(sideMenuObject[side_key].Icon)}>{sideMenuObject[side_key].title}</MenuItem>}
                          {/* <MenuItem icon={<i className="fa-light fa-gauge-simple-high"></i>}>Dashboard</MenuItem> */}
                        </NavLink>
                        : ""
                      : <SubMenuComponent sideChildKey={side_key} parentIt={sideMenuObject[side_key]} />
                    )
                  })}
                  <NavLink
                    id="support"
                    to="/support"
                    onClick={(e) => {
                      setActiveFun();
                    }}
                  >
                    <MenuItem icon={<i className="fa-light fa-circle-question"></i>}>Support</MenuItem>
                  </NavLink>

                  <NavLink
                    id="KnowledgeTutorials"
                    to="/knowledge-tutorials"
                    onClick={(e) => {
                      setActiveFun();
                    }}
                  >
                    <MenuItem icon={<i className="fa-light fa-book"></i>}>
                      Knowledge Tutorials
                    </MenuItem>
                  </NavLink>

                  <NavLink
                    id="chatConfiguration"
                    to="/chat-configuration"
                    onClick={(e) => {
                      setActiveFun();
                    }}
                  >

                    <MenuItem icon={<i className="mb-2"><TbMessageChatbot /></i>}>
                      Chat Configuration
                    </MenuItem>
                  </NavLink>

                </Menu>
              </SidebarContent>
            </ProSidebar>
          </div>
        </div>
      </aside>
    </>
  );
}

export default DefaultSidebar;
