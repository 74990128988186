import axios from "axios";
import { AppConfigData } from "./CommonService";
import { Cookies } from "react-cookie-consent";
export const SubsciptionList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetClientSubscriptionList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const getClientSubscriptionByID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetClientSubscriptionByID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }

};
export const InsertSubscription = async (ReqData: any, accessToken) => {
  try {
    const resInsertSubscription = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/InsertClientSubscription",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });

    return resInsertSubscription;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const UpdateSubscription = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/UpdateClientSubscription",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }

};

export const getSubscriptionByIDPrice = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetSubscriptionByID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }

};

export const ExportSubscriptionList = async (ReqData, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetClientSubscriptionListExport",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const InsertSubscriptionPackageData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/InsertSubscription",
      data: ReqData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const UpdateSubscriptionPackage = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/UpdateSubscription",
      data: ReqData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
