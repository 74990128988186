import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DisplayText, ToasterSuccess } from '../../../src/Service/CommonService';
import { fnCheckValidationOfObject, ToasterError } from '../../Service/CommonService';
import { SavePortfolio, UpdatePortfolio } from '../../Service/PortFolioService';

export const AddPortFolio = (props: any) => {

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const initialSavePortFollio: any = {
    portfolioID: null,
    portfolioName: "",
    isAllPortfolio: false,
    portfolioCode: "",
    clientID: props.ClientID,
    isActive: true,
    updateBy: props.userID,
    errors: {
      portfolioName: "",
      ValidationRules: [
        {
          FieldName: "portfolioName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  }
  const [Logo, setLogo]: any = React.useState({
    name: "",
    link: [],
    errors: "",
  });

  const [LogoUpload, setLogoUpload]: any = React.useState(null);

  function SelectLogo(e: any) {
    setLogo({ ...Logo, ["link"]: e.target.files })
    const fileLoaded: any = URL.createObjectURL(e.target.files[0]);
    setLogoUpload(fileLoaded);
  }
  let [savePortfolio, setsavePortfolio] = useState({
    portfolioID: null,
    portfolioName: "",
    isAllPortfolio: false,
    portfolioCode: "",
    clientID: props.ClientID && props.ClientID,
    isActive: true,
    portfolioLogo: "",
    updateBy: props.userID,
    errors: {
      portfolioName: "",
      ValidationRules: [
        {
          FieldName: "portfolioName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  })
  React.useEffect(() => {
    if (props.portfolioID !== "") {
      if (props.EditPortfolioList) {

        setsavePortfolio(
          {
            ...savePortfolio,
            portfolioID: props.EditPortfolioList && props.EditPortfolioList.portfolioID,
            portfolioName: props.EditPortfolioList && props.EditPortfolioList.portfolioName,
            isAllPortfolio: false,
            portfolioCode: "",
            clientID: props.ClientID,
            portfolioLogo: props.EditPortfolioList && props.EditPortfolioList.portfolioLogo,
            isActive: true,
            updateBy: props.userID,
            errors: {
              portfolioName: "",
              ValidationRules: [
                {
                  FieldName: "portfolioName",
                  ValidationType: "required",
                  ValidationMessage: "This field is required.",
                },
              ],
            },
          }
        )
        setLogoUpload(
          props.EditPortfolioList && props.EditPortfolioList.portfolioLogo
        );
      }
    }
  }, [props.EditPortfolioList])

  const handleClose = () => {
    setsavePortfolio(initialSavePortFollio)
    setLogoUpload(null)
    props.onHide(false);
  }

  async function SavePortFolio() {
    let obj = fnCheckValidationOfObject(savePortfolio);
    setsavePortfolio({
      ...obj.obj,
    });

    let res: any = []
    if (obj.isValid) {

      if (savePortfolio.portfolioID !== null && savePortfolio.portfolioID !== undefined && savePortfolio.portfolioID !== "") {
        let formData: any = new FormData();
        formData.append("PortfolioID", savePortfolio.portfolioID);
        formData.append("PortfolioName", savePortfolio.portfolioName);
        formData.append("IsAllPortfolio", savePortfolio.isAllPortfolio);
        formData.append("PortfolioCode", savePortfolio.portfolioCode);
        formData.append("ClientID", savePortfolio.clientID);
        formData.append("UpdateBy", props.userID);
        formData.append("PortfolioLogo", null);
        formData.append("file", Logo.link[0]);

        setOnProcessHide(true)
        res = await UpdatePortfolio(formData, Token);
        setOnProcessHide(false)

      }
      else {
        setOnProcessHide(true)
        let formData: any = new FormData();

        formData.append("PortfolioName", savePortfolio.portfolioName);
        formData.append("IsAllPortfolio", savePortfolio.isAllPortfolio);
        formData.append("PortfolioCode", savePortfolio.portfolioCode);
        formData.append("ClientID", savePortfolio.clientID);
        formData.append("UpdateBy", props.userID);
        formData.append("PortfolioLogo", null);
        formData.append("file", Logo.link[0]);


        res = await SavePortfolio(formData, Token);
        setOnProcessHide(false)
      }
      if (res.data.success) {
        if (res.data !== undefined) {
          if (res.data.statusCode === 200) {
            ToasterSuccess(res.data.message);
            handleClose();
            props.GetPortFolioList(props.ClientID);
          }
        } else {
          ToasterError(res.data.message);
        }
      }
    }
  }

  return (

    <Modal
      {...props}
      className={props.isHotelPortfolio === true ? "clientpoupadd add_portfolio_hotel_modal" : "clientpoupadd"}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        {
          savePortfolio.portfolioID !== null && savePortfolio.portfolioID !== undefined && savePortfolio.portfolioID !== "" ?
            <Modal.Title id="contained-modal-title-vcenter">Edit Portfolio</Modal.Title>
            :
            <Modal.Title id="contained-modal-title-vcenter">Add Portfolio</Modal.Title>
        }
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row mb-2">

            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Client</Form.Label>
                <Form.Control type="text" maxLength={256}
                  value={props.ClientLabel} disabled />
              </Form.Group>

            </div>
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Portfolio Name* </Form.Label>
                <Form.Control
                  type="text" maxLength={369}
                  defaultValue={savePortfolio.portfolioName}
                  placeholder={DisplayText.PortfolioName}
                  onChange={(e) =>
                    setsavePortfolio({
                      ...savePortfolio,
                      ["portfolioName"]: e.target.value,
                    })
                  }
                  isInvalid={savePortfolio.errors && savePortfolio.errors.portfolioName} />
                {savePortfolio.errors.portfolioName && (
                  <Form.Control.Feedback type="invalid">
                    {savePortfolio.errors.portfolioName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col-md-12 mt-4 mb-2">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="custom-file comaninputfile">
                  {
                    LogoUpload &&
                    <img src={LogoUpload} style={{
                      display: 'flex',
                      border: '2px solid tomato',
                      maxWidth: '300px',
                      maxHeight: '300px',
                    }}
                      alt="" />}
                </div>
                <div className="comninputbtn">
                  <span>Select Logo</span>
                  <input type="file" accept='image/*' id="inputGroupFile01" className=""
                    onChange={SelectLogo}
                  />
                </div>
              </div>

            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="savebtnclient" disabled={OnProcessHide === true ? true : false} onClick={SavePortFolio}>
          <i className="fa-solid fa-floppy-disk me-2"></i>Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};



export default AddPortFolio