import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ToasterError, ToasterSuccess, fnCheckValidationOfObject } from "../../../Service/CommonService";
import { GetKnowledgeTutorialsByPrimaryKey, KnowledgeTutorialsSave } from "../../../Service/KnowledgeTutorials/TutorialsServices";
import { useSelector } from "react-redux";

export const AddKnowledgeTutorial = (props: any) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  let [saveTutorial, setSaveTutorial] = useState({
    title: "",
    url: "",
    tutorialID: "00000000-0000-0000-0000-000000000000",
    errors: {
      title: "",
      url: "",
      ValidationRules: [
        {
          FieldName: "title",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "url",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  })

  async function handleSaveTutorial() {
    let obj = fnCheckValidationOfObject(saveTutorial);
    setSaveTutorial({
      ...obj.obj,
    });

    if (obj.isValid) {
      let input: any = {
        tutorialID: saveTutorial.tutorialID,
        title: saveTutorial.title,
        url: saveTutorial.url,
      }
      let res: any = await KnowledgeTutorialsSave(input, Token)
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            ToasterSuccess("Tutorial Saved Successfully")
            props.fetchTutorialsList()
          } else {
            ToasterError("Something Went Wrong")
          }
        } else {
          ToasterError("Something Went Wrong")
        }
      } else {
        ToasterError("Something Went Wrong")
      }
      props.onHide(true)
    }
  }

  async function fetchTutorialByID(tutorialID) {
    let input: any = { tutorialID: tutorialID }
    let res: any = await GetKnowledgeTutorialsByPrimaryKey(input, Token)
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setSaveTutorial({
            ...saveTutorial,
            ["tutorialID"]: res.data.data.tutorialID,
            ["title"]: res.data.data.title,
            ["url"]: res.data.data.url,
          })
        } else {
          ToasterError("Something Went Wrong")
        }
      } else {
        ToasterError("Something Went Wrong")
      }
    } else {
      ToasterError("Something Went Wrong")
    }
  }

  useEffect(() => {
    if (props.tutorialID !== null) {
      fetchTutorialByID(props.tutorialID)
    }
  }, [])


  return (
    <Modal
      {...props}
      className="clientpoupadd"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Knowledge Tutorial Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row mb-2">

            <div className="col-md-12">
              <Form.Group>
                <Form.Label>Title*</Form.Label>
                <Form.Control type="text" maxLength={256}
                  value={saveTutorial.title}
                  onChange={(e) =>
                    setSaveTutorial({
                      ...saveTutorial,
                      ["title"]: e.target.value,
                    })
                  }
                  isInvalid={saveTutorial.errors && saveTutorial.errors.title}
                />
                {saveTutorial.errors.title && (
                  <Form.Control.Feedback type="invalid">
                    {saveTutorial.errors.title}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

            </div>
            <div className="col-md-12">
              <Form.Group>
                <Form.Label>URL*</Form.Label>
                <Form.Control
                  type="text"
                  value={saveTutorial.url}
                  onChange={(e) =>
                    setSaveTutorial({
                      ...saveTutorial,
                      ["url"]: e.target.value,
                    })
                  }
                  isInvalid={saveTutorial.errors && saveTutorial.errors.url} />
                {saveTutorial.errors.url && (
                  <Form.Control.Feedback type="invalid">
                    {saveTutorial.errors.url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body >
      <Modal.Footer>
        <Button className="savebtnclient"
          // disabled={OnProcessHide === true ? true : false} 
          onClick={() => handleSaveTutorial()}
        >
          <i className="fa-solid fa-floppy-disk me-2"></i>Save
        </Button>
      </Modal.Footer>
    </Modal >
  );

};
export default AddKnowledgeTutorial;
