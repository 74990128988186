import axios from "axios";
import { AppConfigData } from "./CommonService";
import { Cookies } from "react-cookie-consent";
export const getDashboardDetail = async (accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetSuperAdminDashboard",
      // data: ReqData,
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      localStorage.clear()
      window.location.pathname = '/'
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });

    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

//#region Global Search :
// Commented servise before cancell request start :
// export const GetGlobalSearchOrg = async (ReqData: any, accessToken) => {
//   try {
//     const response = await axios({
//       method: "POST",
//       url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetGlobalSearch",
//       data: ReqData,
//       headers: {
//         "Content-Type": "application/json",
//         accept: "*/*",
//         Authorization: "bearer " + accessToken,
//       },
//     });
//     return response;
//   } catch (error: any) {
//     let data: any = null
//     if (error.response.status === 401) {
//       data = {
//         data: {
//           success: false,
//           StatusCode: "401",
//           Message: "Session has been expired.",
//         },
//       };
//       Cookies.remove("SystemSettingDate");
// Cookies.remove("SystemSettingTime");
//       caches.keys().then((names) => {
//         names.forEach((name) => {
//           caches.delete(name);
//         });
//       });
//       localStorage.clear()
//       window.location.pathname = '/'
//     } else {
//       data = {
//         data: {
//           success: false,
//           StatusCode: "02",
//           Message: "Something Wrong in API",
//         },
//       };
//     }
//     return data;
//   }
// }

//
// Commented servise before cancell request end .

let cancelTokensHashMap = new Map();

export const GetGlobalSearch = async (ReqData: any, accessToken) => {
  try {
    if (cancelTokensHashMap.has(AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetGlobalSearch")) {
      cancelTokensHashMap.get(AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetGlobalSearch").cancel();
      cancelTokensHashMap.delete(AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetGlobalSearch");
    }

    // let cancelToken :any =null;
    let cancelToken: any = axios.CancelToken.source();
    cancelTokensHashMap.set(AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetGlobalSearch", cancelToken);


    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetGlobalSearch",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
      cancelToken: cancelToken.token,
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response && error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
// 

export const SendEmailToAllClientAdmin = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/SendEmailToAllClientAdmin",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });

    return response;
  } catch (error) {
    let data: any = {
      data: {
        success: false,
        StatusCode: "02",
        Message: "Something Wrong in API",
      },
    };
    return data;
  }
};
// #endregion