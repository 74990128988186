import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import Select from 'react-select';

const MoveToDifferentClientModal = (props) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const ClientDropdown = dataSelector.ClientListDropdown || [];

  // const [modelData, setModelData] = useState({

  // })
  const [SelectedMoveFrom, setSelectedMoveFrom] = useState({
    value: null,
    label: "Select Client",
  })
  const [SelectedMoveInto, setSelectedMoveInto] = useState({
    value: null,
    label: "Select Client",
  })
  const [optionsClient2, setOptionsClient2] = useState([{
    value: null,
    label: "Select Client",
  }])

  let optionsClient1: any = [{
    value: null,
    label: "Select Client",
  }];

  ClientDropdown.length > 0 &&
    ClientDropdown.map((item: any) => {
      optionsClient1.push({
        value: item.clientID,
        label: item.clientName,
      });
    });

  function handleClientMoveInto(value) {
    if (value) {
      let temp: any = []
      let tempOptionsClient2: any = []
      ClientDropdown.filter((itm) => {
        itm.clientID !== value && temp.push(itm)
      })
      console.log(temp)
      temp.length > 0 &&
        temp.map((item: any) => {
          tempOptionsClient2.push({
            value: item.clientID,
            label: item.clientName,
          });
        });
      setOptionsClient2(tempOptionsClient2)
    } else {
      setOptionsClient2([{
        value: null,
        label: "Select Client",
      }])
    }
  }

  function moveData() {


  }


  return (
    <Modal
      {...props}
      className={props.isHotelPortfolio === true ? "clientpoupadd add_portfolio_hotel_modal" : "clientpoupadd"}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Move Hotel to Different Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className=''>Move From</Form.Label>
                <div className={`col-xl-12 col-lg-12 col-md-12 customselectmy`}>
                  <Select
                    placeholder="Select Move To Client"
                    options={optionsClient1}
                    value={SelectedMoveFrom}
                    onChange={(e: any) => {
                      setSelectedMoveFrom(e)
                      setSelectedMoveInto({
                        value: null,
                        label: "Select Client",
                      })
                      handleClientMoveInto(e.value)
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className=''>Move Into</Form.Label>
                <div className={`col-xl-12 col-lg-12 col-md-12 customselectmy`}>
                  <Select
                    placeholder="Select Move in Client"
                    options={optionsClient2}
                    value={SelectedMoveInto}
                    onChange={(e: any) => {
                      setSelectedMoveInto(e)
                      handleClientMoveInto(e.value)
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>

      </Modal.Body>
      <Modal.Footer className='mt-5'>
        <Button
          className="savebtnclient  me-2 align-right"
          // disabled={OnProcessHide === true ? true : false} 
          onClick={() => moveData()}
        >
          <i className="fa-solid fa-floppy-disk me-2"></i>Move
        </Button>
      </Modal.Footer>

    </Modal >
  )
}

export default MoveToDifferentClientModal