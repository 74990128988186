import axios from "axios";
import { AppConfigData } from "./CommonService";
import { Cookies } from "react-cookie-consent";
export const AccountFileUpload = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/ImportAccountsPreview",
      data: ReqData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const AccountImportSave = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/ImportAccounts",
      data: ReqData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}; export const ContactFileUpload = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/ImportContactsPreview",
      data: ReqData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const ContactImportSave = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/ImportContacts",
      data: ReqData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SuspectFileUpload = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/ImportSuspectPreview",
      data: ReqData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SuspectImportSave = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/ImportSuspect",
      data: ReqData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};