import React, { useEffect, useState } from 'react'
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useSelector } from 'react-redux';
import { SendEmailToAllClientAdmin } from '../../Service/DashboardService';
import { toast } from 'react-toastify';
import { CommonService, ToasterError, ToasterSuccess } from '../../Service/CommonService';
import { getEmailSettingList } from '../../Service/EmailSettingService';
import Select from 'react-select';

export default function SendEmailToClients({ modalShow, setModalShow }) {
    const dataSelector: any = useSelector((state: any) => state.session);
    const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
    const Token = dataSelector.access_Token;

    const [isLoading, setIsLoading] = useState(false);

    let initialFormInputs = {
        subject: "",
        emailBody: "",
        emailAccountID: ""
    }

    let initialFormInputsEr = {
        subject: "",
        emailBody: "",
        emailAccountID: ""
    }
    const [formInputs, setFormInputs] = useState<any>(initialFormInputs)
    const [formInputsEr, setFormInputsEr] = useState<any>(initialFormInputsEr)
    let [EmailSettingList, setEmailSettingList]: any = React.useState([]);

    const handleClosePopup = () => {
        setModalShow(false)
        setFormInputsEr(initialFormInputsEr)
        setFormInputs(initialFormInputs)
    }

    const validateForm = () => {

        let error_json = {}
        Object.keys(formInputsEr).forEach((frm_key) => {
            error_json = {
                ...error_json,
                [frm_key]: ""
            }
        })
        let is_validate = true;

        Object.keys(formInputsEr).forEach((frm_key) => {
            if (!formInputs[frm_key] || formInputs[frm_key] === "") {
                error_json = {
                    ...error_json,
                    [frm_key]: "This field is required."
                }
                is_validate = false
            }
        })
        setFormInputsEr(error_json)
        return is_validate
    }

    let optionsEmail: any = [{
        value: "",
        label: "Select EmailID",
    }];
    EmailSettingList.length &&
        EmailSettingList.map((item: any) => {
            optionsEmail.push({
                value: item.emailAccountID,
                label: item.emailID,
            })
        });

    useEffect(() => {
        getEmailSettingListData()
    }, [])

    async function getEmailSettingListData() {
        let input = {
            clientID: null,
            emailAccountID: null,
            emailID: "",
        }

        let res: any = await getEmailSettingList(input, Token);
        if (res.data.success) {
            if (res !== undefined) {
                if (res.data !== undefined) {
                    if (res.data.data !== undefined && res.data.data !== null) {
                        setEmailSettingList(res.data.data.emailAccount);
                    }
                }
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            setIsLoading(true)
            let res = await SendEmailToAllClientAdmin(formInputs, Token)
            if (res.data.success) {
                if (res.data.statusCode === 200) {
                    if (res.data.data && typeof res.data.data !== 'undefined') {
                        setIsLoading(false)
                        ToasterSuccess(res.data.message)
                        handleClosePopup()
                    } else {
                        setIsLoading(false)
                    }
                } else {
                    setIsLoading(false)
                    ToasterError("No users found to send email.")
                }
            } else {
                setIsLoading(false)
                ToasterError(res.data.message)
            }
        }
    }

    return (<Modal
        show={modalShow}
        className="clientpoupadd"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => handleClosePopup()}
        centered
    >

        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Send Email - CRM Updates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <div className="row">




                    <Form.Group>
                        <Form.Label className=''>Email Send From</Form.Label>
                        <div className={`col-xl-12 col-lg-12 col-md-12 customselectmy ${formInputsEr.emailAccountID && "red-border-masking-send-mail"}`}>
                            <Select
                                placeholder="Select EmailID"
                                options={optionsEmail}
                                onChange={(e: any) =>
                                    setFormInputs({
                                        ...formInputs,
                                        emailAccountID: e.value,
                                    })
                                }
                            />
                        </div>
                    </Form.Group>

                    <small className='mt-2' style={{ color: "#dc3545" }}>
                        {formInputsEr && formInputsEr.emailAccountID}
                    </small>
                    <div className="col-md-12 mb-4">
                        <Form.Group>
                            <Form.Label className='ps-0 ms-0'>Subject</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Subject"
                                value={formInputs.subject}
                                onChange={(e) =>
                                    setFormInputs({
                                        ...formInputs,
                                        subject: e.target.value,
                                    })
                                }
                                isInvalid={formInputsEr.subject && formInputsEr.subject}
                            />
                            {formInputsEr.subject && (
                                <Form.Control.Feedback type="invalid">
                                    {formInputsEr.subject}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </div>

                    <div className="col-md-12">
                        <Form.Group>
                            <Form.Label className='ps-0 ms-0'>Message</Form.Label>
                        </Form.Group>
                        <div className={`${formInputsEr.emailBody && "red-border"}`}>
                            <CKEditor
                                editor={Editor}
                                config={{
                                    extraPlugins: [CommonService.uploadPlugin]
                                }}
                                data={formInputs.emailBody}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFormInputs({
                                        ...formInputs,
                                        emailBody: data,
                                    })
                                }}
                            />
                        </div>
                        {formInputsEr.emailBody && (
                            <span className="feedbackss">{formInputsEr.emailBody}</span>
                        )}
                    </div>

                </div>
            </Form>
        </Modal.Body >
        <Modal.Footer>
            {!isLoading ? <Button className="savebtnclient" onClick={(e) => { handleSubmit(e) }}>
                <i className="fa-solid fa-floppy-disk me-2"></i>Send to All Clients
            </Button> : <Button type="button" variant="primary">
                <Spinner size="sm" className="me-2" animation="border" variant="light" />
                Loading
            </Button>}

        </Modal.Footer>
    </Modal >
    )
}
