import axios from "axios";
import { AppConfigData } from "./CommonService";
import { Cookies } from "react-cookie-consent";

export const getUserList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetClientUserList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

export const getAdminUserList = async (accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetAdminUserList",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

export const getEditUserDetails = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetUserByID_New",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

export const GetUserByIDClientDB = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetUserByIDClientDB",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

export const UpdateUserEmailByID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/UpdateUserEmailByID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

export const getUserRoleList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetUserRoleList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

export const getClientUserRoleList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetClientUserRoleList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

// export const getUserRoleRightsList = async (ReqData: any, accessToken) => {
//   try {
//     const response = await axios({
//       method: "POST",
//       url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetUserRoleRightByID",
//       data: ReqData,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "bearer " + accessToken,
//       },
//     });
//     return response;
//   } catch (error: any) {
//     let data: any = null
//     if (error.response.status === 401) {
//       data = {
//         data: {
//           success: false,
//           StatusCode: "401",
//           Message: "Session has been expired.",
//         },
//       };
//       Cookies.remove("SystemSettingDate");
// Cookies.remove("SystemSettingTime");
// caches.keys().then((names) => {
//   names.forEach((name) => {
//     caches.delete(name);
//   });
// });
//       localStorage.clear()
//       window.location.pathname = '/'
//     } else {
//       data = {
//         data: {
//           success: false,
//           StatusCode: "02",
//           Message: "Something Wrong in API",
//         },
//       };
//     }
//     return data;
//   }
// }
export const getRightsList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetRights",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const SaveUpdateUserRole = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/InsertUpdateRoleRights",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const getUserPortfolioList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetPortfolioList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const getPropertiesByPortfolioID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetPropertiesByPortfolioID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const getUserType = async (accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetUserType",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const getInsertUserClient = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/InsertUserRole",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const getEditUserRoleRights = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetUserRoleRightByID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const DeleteUserRoleRights = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/DeleteUserRole",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

export const getUpdateUser = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/UpdateUsers",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const getPropertiesByPortFolioMultipleID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetPropertiesByPortfolioIDs",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
//#region AdditionalSetings Category List
export const getProjectTermCategoryList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetProjectTermCategoryList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
//#endregion

//#region AdditionalSetings Category Details By Category type :
export const getProjectTermByCategory = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetProjectTermByCategory",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
//#endregion
export const checkUserLimit = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/ContractedUsersCheckLength",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}
export const SendEmailToUser = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/SendClientUserPasswordEmail",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

export const SendEmailToAdminUser = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/SendAdminUserPasswordEmail",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
}

export const ExportUserList = async (ReqData, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      // url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetUserListExport",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetClientUserListExport",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const ExportAdminUserList = async (ReqData, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      // url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetUserListExport",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetAdminUserListExport",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const ExportUserRoleList = async (accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetUserRoleListExport",
      // data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
