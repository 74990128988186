import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PageLoader from '../../../Component/Loader/PageLoader';
import { GetPageRight, ToasterSuccess } from '../../../Service/CommonService';
import { GetThirdPartyWebsiteLoginDetailList, ThirdPartyWebsiteLoginDetailSave } from '../../../Service/PropertieService';

export default function ThirdPartyWebsite({ property_Data }) {

    let page_rights_Hotel = GetPageRight("Hotels");

    const dataSelector: any = useSelector((state: any) => state.session);
    const Token = dataSelector.access_Token;

    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)

    const [count, setCount] = useState(1)

    const [dataRes, setDataRes] = useState({
        1: {
            loginWebsiteURL: "",
            accountNumber: "",
            userID: "",
            password: ""
        }
    })


    const handleSaveData = async () => {
        let dataToSend: any = []
        Object.keys(dataRes).forEach((res_key) => {
            dataToSend = [...dataToSend, dataRes[res_key]]
        })
        let data = {
            listClientThirdPartyWebsite: dataToSend,
            propertyID: property_Data.property_id,
            clientID: property_Data.client_id,
        }
        setSubmitLoading(true)

        let res: any = await ThirdPartyWebsiteLoginDetailSave(data, Token);
        if (res.status === 200) {
            if (res.data.success) {
                setSubmitLoading(false)
                ToasterSuccess(res.data.message)
            } else {
                setSubmitLoading(false)
            }
        } else {
            setSubmitLoading(false)
        }
    }

    const fetchThirdPartyWebsiteDetails = async () => {

        let data = {
            propertyID: property_Data.property_id,
            clientID: property_Data.client_id,
        }
        let res: any = await GetThirdPartyWebsiteLoginDetailList(data, Token);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.data) {
                    let jsonToSet: any = {}
                    res.data.data.dataList1.forEach((res_data_lst, i) => {
                        jsonToSet = {
                            ...jsonToSet,
                            [i]: {
                                loginWebsiteURL: res_data_lst.LoginWebsiteURL ? res_data_lst.LoginWebsiteURL : "",
                                accountNumber: res_data_lst.AccountNumber ? res_data_lst.AccountNumber : "",
                                userID: res_data_lst.UserID ? res_data_lst.UserID : "",
                                password: res_data_lst.Password ? res_data_lst.Password : ""
                            }
                        }
                    })
                    setDataRes(jsonToSet)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }


    useEffect(() => {
        if (property_Data.property_id) {
            fetchThirdPartyWebsiteDetails()
        }
    }, [])


    return (!loading ? <>
        <div className="information-division">
            <Row>
                <Col>
                    <h3 className="inner-header m-0">Third Party Website Login Details</h3>
                    <div className='tab-inner-box position-relative'>
                        <Form>
                            <div className="customtablemetting">
                                <Table responsive bordered className="table-without-space mb-0">
                                    <thead>
                                        <tr className="h-40">
                                            <th className="w-243">
                                                Website Url
                                            </th>
                                            <th>
                                                Account No.
                                            </th>
                                            <th>
                                                User Name
                                            </th>
                                            <th>
                                                Password
                                            </th>
                                            <th className="text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.keys(dataRes).map((data_key) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td className="bordernone">
                                                            <Form.Group>
                                                                <Form.Control
                                                                    className='input-tabal ps-0 pe-0' onChange={(e) => {
                                                                        setDataRes({
                                                                            ...dataRes,
                                                                            [data_key]: {
                                                                                ...dataRes[data_key],
                                                                                loginWebsiteURL: e.target.value ? e.target.value : ""
                                                                            }
                                                                        })
                                                                    }} value={dataRes[data_key].loginWebsiteURL} />
                                                            </Form.Group>
                                                        </td>
                                                        <td className="bordernone">
                                                            <Form.Group>
                                                                <Form.Control
                                                                    className='input-tabal ps-0 pe-0' onChange={(e) => {
                                                                        setDataRes({
                                                                            ...dataRes,
                                                                            [data_key]: {
                                                                                ...dataRes[data_key],
                                                                                accountNumber: e.target.value ? e.target.value : ""
                                                                            }
                                                                        })
                                                                    }} value={dataRes[data_key].accountNumber} /> </Form.Group>
                                                        </td>
                                                        <td className="bordernone">
                                                            <Form.Group>
                                                                <Form.Control
                                                                    autoComplete="new-password"
                                                                    className='input-tabal ps-0 pe-0' onChange={(e) => {
                                                                        setDataRes({
                                                                            ...dataRes,
                                                                            [data_key]: {
                                                                                ...dataRes[data_key],
                                                                                userID: e.target.value ? e.target.value : ""
                                                                            }
                                                                        })
                                                                    }} value={dataRes[data_key].userID} />
                                                            </Form.Group>
                                                        </td>
                                                        <td className="bordernone">
                                                            <Form.Group>
                                                                <Form.Control
                                                                    autoComplete="new-password"
                                                                    className='input-tabal ps-0 pe-0' type={"password"} onChange={(e) => {
                                                                        setDataRes({
                                                                            ...dataRes,
                                                                            [data_key]: {
                                                                                ...dataRes[data_key],
                                                                                password: e.target.value ? e.target.value : ""
                                                                            }
                                                                        })
                                                                    }} value={dataRes[data_key].password} />
                                                            </Form.Group>
                                                        </td>
                                                        <td className="bordernone">
                                                            <div className="btn-icon-grp-table justify-content-center">
                                                                <Button onClick={() => {

                                                                    let newJson: any = {}
                                                                    Object.keys(dataRes).forEach(res_key => {
                                                                        if (res_key != data_key) {
                                                                            newJson = {
                                                                                ...newJson,
                                                                                [res_key]: dataRes[res_key]

                                                                            }
                                                                        }
                                                                    });
                                                                    if (Object.keys(newJson).length) {
                                                                        setDataRes(newJson)
                                                                    } else {
                                                                        setDataRes({
                                                                            [1]: {
                                                                                loginWebsiteURL: "",
                                                                                accountNumber: "",
                                                                                userID: "",
                                                                                password: ""
                                                                            }
                                                                        })
                                                                        setCount(1)
                                                                    }
                                                                }} type="button" className="btn-icon cancelIcon"></Button>
                                                            </div>
                                                        </td>
                                                    </tr></>)
                                        })}
                                        <tr>
                                            <td className="bluetext bg-white">
                                                <div onClick={() => {
                                                    setDataRes({
                                                        ...dataRes,
                                                        [count + 1]: {
                                                            loginWebsiteURL: "",
                                                            accountNumber: "",
                                                            userID: "",
                                                            password: ""
                                                        }
                                                    })
                                                    setCount(count + 1)
                                                }} role="button" tabIndex={0}

                                                    onKeyDown={() => {
                                                        setDataRes({
                                                            ...dataRes,
                                                            [count + 1]: {
                                                                loginWebsiteURL: "",
                                                                accountNumber: "",
                                                                userID: "",
                                                                password: ""
                                                            }
                                                        })
                                                        setCount(count + 1)
                                                    }}>
                                                    Add More
                                                </div>
                                            </td>
                                            <td colSpan={6} className="settitle bg-white">
                                                <Form.Control
                                                    disabled
                                                    type="text"
                                                    maxLength={15}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table >
                            </div>
                            <Col>
                                {!submitLoading ? <Button
                                    onClick={(e) => {
                                        if (page_rights_Hotel.is_create) {
                                            e.preventDefault()
                                            handleSaveData()
                                        } else {
                                            CommonService.unauthorizedAction();
                                        }
                                    }}
                                    className="savebtnclient"
                                    type="button"
                                >
                                    <i className="fa-solid fa-floppy-disk me-2"></i>Save
                                </Button> : <Button className="btn btn-primary justify-content-center" type="button">
                                    <Spinner size="sm" animation="border" variant="light" />
                                </Button>}
                            </Col>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    </> : <PageLoader />)
}
